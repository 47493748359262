body {
  font-family: Helvetica, Arial, sans-serif, Segoe UI Emoji, BlinkMacSystemFont,
    -apple-system;
}
.Login-selection-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.Login-section {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  backdrop-filter: blur(24px) brightness(100%);
  height: 800px;
  overflow: hidden;
  width: 730px;
}

.Login-overlap-group {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  backdrop-filter: blur(24px) brightness(100%);
  background-color: #032c65;
  height: 800px;
  overflow: relative;
  width: 732px;
}
.login_button {
  border: 1px solid #2e518b;
  padding: 15px;
  background-color: #2e518b;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 50px;
  width: 70%;
  cursor: pointer;
}
.login_button:hover {
  background-color: #45a049;
}
.login_button:active {
  background-color: #3e8e41;
}
.Login-paper {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  left: 322px;
  position: absolute;
  top: 744px;
  width: fit-content;
}

.Login-element {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  padding: 8px;
  position: relative;
  width: fit-content;
}
.Login-ellipse {
  background-color: #032c65;
  border-radius: 4px;
  height: 8px;
  min-width: 8px;
  position: relative;
}

.Login-overlap {
  height: 122px;
  left: 116px;
  position: absolute;
  top: 500px;
  width: 489px;
}

.Login-element-wire-decoder-controller {
  color: #ffffff;

  font-size: 24px;
  /*font-weight: 600;*/
  height: 96px;
  left: 4px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 486px;
}
.Login-text-wrapper {
  color: #ffffff;

  font-size: 40px;
  /*font-weight: 700;*/
  height: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 80px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
  top: 0;
  white-space: nowrap;
  width: 486px;
}

.Login-overlap-group1 {
  height: 244px;
  left: 278px;
  position: absolute;
  top: 195px;
  width: 267px;
}
.Login-h-1 {
  color: #032c65;

  font-size: 48px;
  /*font-weight: 700;*/
  left: 137px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 97px;
  white-space: nowrap;
}

.Login-krainlogowht {
  height: 244px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 175px;
}

.log-in-log-in-wrapper {
  background-color: #d9dbe0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  height: 100vh;
  overflow: auto;
}
.log-in-log-in {
  top: 100px;
  background-color: #f9f9f9;
  border: 1px none;
  height: 800px;
  overflow: hidden;
  width: 1440px;
  margin: 0 auto 100px;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.log-in-overlap {
  height: 800px;
  position: relative;
  width: 1415px;
}
.log-in-form {
  height: 800px;
  left: 707px;
  position: absolute;
  top: 24px;
  width: 708px;
}

.log-in-div {
  height: 468px;
  left: 122px;
  position: relative;
  top: 120px;
  width: 463px;
}
.log-in-inputs {
  height: 314px;
  left: -2px;
  position: absolute;
  top: 103px;
  width: 465px;
}
.log-in-inputs-pass {
  height: 84px;
  left: 2px;
  position: absolute;
  top: 115px;
  width: 467px;
}
.log-in-text-pass {
  color: #18345e;

  font-size: 16px;
  left: -32px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
  width: 143px;
}
.swal-overlay {
  z-index: 999999 !important;
}

.log-in-shadow {
  align-items: center;
  background-color: #f4f2f2;
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: space-between;
  left: 2px;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  top: 32px;
  width: 463px;
}

.log-in-text {
  color: #18345e;

  font-size: 16px;
  /*font-weight: 500;*/
  letter-spacing: 0;
  line-height: 24px;
  opacity: 0.5;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
.log-in-vector {
  height: 20px;
  min-width: 25px;
  position: relative;
}

.log-in-inputs-email {
  height: 78px;
  left: 4px;
  position: absolute;
  top: 15px;
  width: 466px;
}
.log-in-inputs-text-email {
  color: #18345e;

  font-size: 16px;
  /*font-weight: 400;*/
  left: -20px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 174px;
}

.log-in-shadow-2 {
  align-items: center;
  background-color: #f4f2f2;
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  top: 26px;
  width: 464px;
}
.log-in-input-style {
  background-color: #f4f2f2;
  border-radius: 6px;
  display: flex;
  height: 52px;
  width: 438px;
  position: relative;
  overflow: hidden;
  border-color: transparent;
  border: none;
  font-size: 20px;
  color: #032c65;
}

.log-in-text-2 {
  color: #18345e;

  font-size: 16px;
  /*font-weight: 500;*/
  letter-spacing: 0;
  line-height: 24px;
  opacity: 0.5;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.log-in-vector-2 {
  height: 20px;
  min-width: 25px;
  position: relative;
}

.log-in-submit-btn {
  height: 51px;
  left: 2px;
  position: absolute;
  top: 250px;
  width: 462px;
}
.log-in-text-lg {
  color: transparent;
  cursor: pointer;

  font-size: 18px;
  left: 104px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 65px;
  white-space: nowrap;
}

.log-in-span {
  color: #312e81cc;
}

.log-in-text-wrapper-2 {
  color: #312e8100;
}
.log-in-text-wrapper-3 {
  color: #18345e;
}
.log-in-text-wrapper-4 {
  color: #032c65;
  /*font-weight: 700;*/
}

@media screen and (max-width: 800) {
  .log-in-text-wrapper-2 {
    color: #312e8100;
  }
  .log-in-text-wrapper-3 {
    color: #18345e;
  }
  .log-in-text-wrapper-4 {
    color: #032c65;
    /*font-weight: bold;*/
    text-align: center;
    align-content: center;
  }
  .log-in-log-in {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9;
    border: 1px none;
    height: 1px;
    overflow: hidden;
    width: 11;
    margin: 0 auto 100px;
    z-index: 1;
    position: relative;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }
}

.log-in-top-text {
  height: 103px;
  left: 0;
  position: relative;
  top: 0;
  width: 367px;
}
.log-in-text-5xl {
  color: #18345e;

  font-size: 48px;
  /*font-weight: 700;*/
  left: 0;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}
.section {
  height: 1024px;
  left: 0;
  position: absolute;
  top: 0;
  width: 715px;
}
