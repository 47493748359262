.dash-buttons {
  display: flex;
  justify-content: space-between;
}

.dash-buttons {
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
  padding: 20px;
  font-size: 20px;
  background-color: #fff;
  margin-right: 10px;
  bottom: 30%;
  border: 1px grey;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button-programs-zones:hover {
  transition: 0.5s;

  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
  background-color: #50d765;
}
.custom-button-clear-fault:hover {
  transition: 0.5s;

  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
  background-color: #d0d015;
}
.custom-button-auto:hover {
  transition: 0.5s;

  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
}

.custom-button-metrics {
  border-radius: 8px;
  padding: 30px;
  font-size: 20px;
  background-color: #fff;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
  border: 1px grey;
  margin-bottom: 10%;
}

.scrollable-container {
  position: fixed;
  bottom: 100px;
  background-color: #ffffff;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  border: 5px solid;
  border-radius: 8px;
}
.grid-container {
  border: none;
}

.checkList {
  position: absolute;
  width: 50%;
  right: 25%;
}
.column-item {
  /* Estilos para los elementos */
  border: 2px solid #000000;
  padding: 5px;
  border-radius: 8px;
  margin: 0;
}

.dash-metrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .dash-metrics {
    flex-direction: column;
    align-items: center;
  }
}

.board-grid-zone-program {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
}
.zone-card-runningZ {
  flex: 0 0 calc(35% - 15px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.zone-card-running-mobile {
  flex: 0 0 calc(35% - 10px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.zone-card-runningZ:nth-child(4n) {
  margin-right: 0;
}

.zone-card-runningZ:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.zone-card-running-mobile:nth-child(4n) {
  margin-right: 0;
}

.zone-card-running-mobile:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.button-on:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
