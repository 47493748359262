.container {
  width: 100%;
  position: absolute;
  height: 60%;
  padding-left: 20%;
  top: 20%;
}
@media (max-width: 600px) {
  .modal-style {
    width: 90%;
    height: 90%;
  }
}
.controller-dash-containers {
  /* padding-left: 15%;
  padding-right: 10%;
  padding-top: 0%; */
  border-collapse: collapse;
}

.head {
  align-items: center;
  border: 1px solid;
  border-color: #edeff2;
  border-radius: 20px;
  display: flex;
  height: 69px;
  justify-content: space-between;
  position: relative;
}
.table-style {
  border-collapse: collapse;
  border: 500;
}
.button-new-controller-style {
  color: #032c65;
  background-color: #032c65;
}

.header-table-title-style {
  font-size: 20;
  color: #6b7a99;
}

.Columnsclasss {
  font-size: 20px;
}

.head .dashboard-projects {
  color: #6b7a99;

  font-size: 16px;
  font-weight: 700;
  position: relative;
  text-align: center;
  width: 200px;
}

.head .design-component-instance-node {
  color: var(--gray-bluegrey-blue-80) !important;

  font-size: var(--bold-12-font-size) !important;
  font-style: var(--bold-12-font-style) !important;
  font-weight: var(--bold-12-font-weight) !important;
  letter-spacing: var(--bold-12-letter-spacing) !important;
  line-height: var(--bold-12-line-height) !important;
  margin-top: unset !important;
}

.head .action-search-instance {
  margin-bottom: -2.5px !important;
  margin-top: -2.5px !important;
}

.head .add-new-controller {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  padding: 0px 26px 0px 0px;
  position: relative;
}

.head .add-new-controller-label {
  color: #7d8fb3;

  font-size: 13px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: fixed;
  top: 0;
  white-space: nowrap;
}

.container-fluid {
  border: 1px solid;
  width: 50%;
  height: 50px;
  border-radius: 10px;
  position: absolute;
  left: 20%;
  top: 10%;
}
.container-table-controller {
  position: absolute;
  left: 10%;
}

table {
  padding: 5px;
}

.Container-h1 {
  font-size: 30px;
}
