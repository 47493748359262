body {
  margin: 0;
  padding: 0;
}

.app {
  margin: 0;
  padding: 0;
}

.scrollable-containerEdit {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 15px;
  border-radius: 15px;
  margin: 3;
  font-size: large;
  color: #032c65;
  left: 10px;
  position: relative;
  background-color: #ffffff;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  border: 5px solid;
  border-radius: 10px;
  object-fit: contain;
}

.container-table-controller {
  position: relative;
  left: 10%;
}

table {
  padding: 5px;
}

.Container-h1 {
  font-size: 30px;
}

.card {
  position: absolute;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 20vw;
  height: 70%;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
  padding: 1;
  top: 65%;
}

.Start_Times {
  background-color: white;
  border-radius: 8px;
}

.Start_Times form {
  display: grid;
  grid-template-columns: 2fr;
  gap: 3px;
}

.Start_Times label {
  text-align: center;
  color: #032c65;
}

.Start_Times input {
  justify-self: center;
  border-radius: 8px;
}
.card-mobile {
  position: relative;
  left: 50%;
  transform: translate(-42%, -5%);
  font-size: 12px;
  width: 80vw;
  height: 35vh;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
}

.Start_Times-mobile {
  font-size: 16px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);
  padding: 1;
}

.time-container {
  overflow: hidden;
  height: 100%;
}

.time-form {
  display: flex;
  justify-content: space-around;
}

.time-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-content {
  position: absolute;
  top: 60%;
  left: 35%;

  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  width: 20vw;
  height: 65%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(5, 11, 0, 0.4);

  object-fit: contain;
}

.styless {
  border-radius: 15px;
}

.runtime-board-container {
  max-width: 50%;
  margin: 0 12%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 25px;
  transition: box-shadow 0.3s ease-in-out;
  height: 600px;
}

@media screen and (max-width: 800px) {
  .runtime-board-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    max-width: 100%;
    margin: 42px;
    height: 40vh;
    position: relative;
    left: 8%;
    overflow-x: auto;
    border: 1px solid #ddd;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.runtime-board-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.board-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #032c65;
}

.runtime-board {
  overflow-y: auto;
  max-height: 500px;
}
.board-grid-zone-program {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.board-grid-zone-program-Mobile {
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  overflow-y: auto;
  object-fit: contain;
  left: 10%;
}
.zone-card {
  flex: 0 0 calc(25% - 20px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 60px;
}

.zone-card:nth-child(4n) {
  margin-right: 0;
}

.zone-card:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.BoxContainingGrid {
  max-width: 20%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 25px;
  border: 2px solid #ddd;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  left: 75%;
  bottom: 600px;
}

.BoxContainingGridMobile {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  margin-top: 60%;

  left: 57%;
  overflow: auto;
  transform: translate(-50%, -50%);
  width: 80vw;
  overflow: auto;

  height: 40vh;
  font-size: medium;
}

@media (max-width: 800px) {
  .zone-card {
    overflow: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    height: 15vh;
    width: 22vw;
    overflow-y: auto;
  }
}
.board-gridMobile {
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow: auto;
  object-fit: contain;
  height: 60vh;
}

.board-grid {
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow-y: auto;
  max-height: 70%;
  overflow: auto;
}

.check-card {
  flex: 0 0 calc(33.333% - 10px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.check-card:nth-child(4n) {
  margin-right: 0;
}

.check-card:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.checked-item {
  color: green;
  /*font-weight: bold;*/
}

.not-checked-item {
  color: #032c65;
}

.button-new-controller-style {
  transition: transform 0.3s ease-in-out;
}

.button-new-controller-style.selected {
  background-color: #32cd32;
}

.button-new-controller-style:active {
  transform: translateY(2px);
}
