.sign-up-sign-up-wrapper {
  background-color: #d9dbe0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  height: 100vh;
}

.sign-up-sign-up {
  top: 100px;
  background-color: #f9f9f9;
  border: 1px solid grey;
  height: 800px;
  overflow: hidden;
  width: 1440px;
  margin: 0 auto 100px;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.4);
}
.sign-up-overlap {
  height: 800px;
  position: relative;
  width: 1415px;
}

.sign-up-form {
  height: 800px;
  left: 45%;
  width: 708px;
  object-fit: contain;
  position: fixed;
  top: 5%;
}
.sign-up-div {
  height: 468px;
  left: 122px;
  position: relative;
  top: 50px;
  width: 463px;
}
.sign-up-submit-btn {
  cursor: pointer;
  height: 42px;
  left: 150px;
  position: absolute;
  top: 700px;
  width: 462px;
}

.sign-up-text-lg {
  color: transparent;
  cursor: pointer;

  font-size: 18px;
  /*font-weight: 400;*/
  left: 104px;
  letter-spacing: 0;
  line-height: 0;
  line-height: 28px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
}
.sign-up-text-wrapper {
  color: #312e81cc;
}
.sign-up-span {
  color: #312e8180;
}
.sign-up-wrapper-2 {
  color: #032c65;
}
.sign-up-wrapper-3 {
  color: #32b30b;
  /*font-weight: 700;*/
}
.sign-up-overlap-group {
  /*font-weight: bold;*/
  top: 30px;
  position: relative;
}
.sign-up-text {
  color: #ffffff;

  font-size: 18px;
  /*font-weight: 700;*/
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 462px;
}
.form-group-overlap {
  height: 459px;
  position: absolute;
  top: 0;
  width: 465px;
}
.form-group-inputs {
  height: 84px;
  left: 100px;
  position: absolute;
  top: 65px;
  width: 223px;
}

.form-group-password {
  height: 84px;
  left: 270px;
  position: absolute;
  top: 470px;
  width: 223px;
}
.form-group-text-base {
  color: #032c65;

  font-size: 16px;
  /*font-weight: 400px;*/
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}
.form-group-shadow {
  align-items: center;
  background-color: #f4f2f2;
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: space-between;
  left: 1px;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  top: 32px;
  width: 100%;
}
/* .form-group-input-style{
    background-color:  #f4f2f2;
    border-radius: 6px;
    display: flex;
    height: 52px;
    width: 438px;
    position: relative;
    overflow: hidden;
    border-color: transparent;
    border: none;
    font-size: 24px;
    color: #032c65;

} */

.form-group-input-style {
  background-color: #f4f2f2;
  border-radius: 6px;
  display: flex;
  height: 52px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-color: transparent;
  border: none;
  font-size: 20px;
  color: #000000;
  outline: none;
}

.form-group-vector-2 {
  height: 25px;
  min-width: 21.88px;
  position: relative;
}
.form-group-vector {
  height: 20px;
  min-width: 25px;
  position: relative;
}
.form-group-password-2 {
  height: 84px;
  left: 0;
  position: absolute;
  top: 470px;
  width: 223px;
}
.form-group-shadow-2 {
  align-items: center;
  background-color: #f4f2f2;
  border-radius: 2px;
  display: flex;
  height: 52px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 12px 13px 12px 12px;
  position: absolute;
  top: 25px;
  width: 373px;
}
/* .form-group-shadow-2:focus{
    align-items: center;
    background-color: #f4f2f2;
    border-radius: 1px;
    display: flex;
    height:52px;
    justify-content: space-between;
    left: 0;
    overflow: hidden;
    padding: 12px 13px 12px 12px;
    position: absolute;
    top: 25px;
    width: 373px;
    border:solid ;
    border-color: red;

} */

.form-group-inputs-email {
  height: 78px;
  left: 1px;
  position: absolute;
  top: 120px;
  width: 466px;
}
.form-group-inputs-username {
  height: 78px;
  left: 1px;
  position: absolute;
  top: 360px;
  width: 466px;
}
.form-group-text-base-2 {
  color: #032c65;

  font-size: 16px;
  /*font-weight: 400px;*/
  left: -55px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 174px;
}

.form-group-inputs-lname {
  height: 84px;
  left: 270px;
  position: absolute;
  top: 5;
  width: 223px;
}

.form-group-inputs-companyName {
  height: 82px;
  left: 0;
  top: 10px;
  width: 223px;
}
.form-group-text-base-3 {
  color: #032c65;

  font-size: 16px;
  /*font-weight: 400px;*/
  letter-spacing: 0;
  left: -40px;
  line-height: 24px;
  position: absolute;
  top: 240px;
  white-space: nowrap;
  width: 195px;
}
.form-group-shadow-3 {
  align-items: center;
  background-color: #f4f2f2;
  border-radius: 6px;
  display: flex;
  height: 52px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 12px 13px 12px 12px;
  position: absolute;
  top: 272px;
  width: 373px;
}

/* LEFT SIDE BLUE SIDE */

.signup-section {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  backdrop-filter: blur(24px) brightness(100%);
  height: 800px;
  overflow: hidden;
  width: 730px;
}
.signup-overlap-group {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  backdrop-filter: blur(24px) brightness(100%);
  background-color: #032c65;
  height: 800px;
  overflow: relative;
  width: 732px;
}

.signup-overlap {
  height: 122px;
  left: 116px;
  position: absolute;
  top: 500px;
  width: 489px;
}

.signup-element-wire-decoder-controller {
  color: #ffffff;

  font-size: 24px;
  /*font-weight: 600;*/
  height: 96px;
  left: 4px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 486px;
}
.signup-text-wrapper {
  color: #ffffff;

  font-size: 40px;
  /*font-weight: 700;*/
  height: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 80px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
  top: 0;
  white-space: nowrap;
  width: 486px;
}
.signup-overlap-group1 {
  height: 244px;
  left: 278px;
  position: absolute;
  top: 195px;
  width: 267px;
}
.signup-krainlogowht {
  height: 244px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 175px;
}

.sign-up-top-text {
  height: 103px;
  left: 0;
  position: relative;
  top: 0;
  width: 367px;
}
.sign-up-text-5xl {
  color: #18345e;

  font-size: 48px;
  /*font-weight: 700;*/
  left: 0;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.signup_button {
  border: 1px solid #2e518b;
  padding: 15px;
  background-color: #2e518b;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;

  border-radius: 50px;
  width: 70%;
  cursor: pointer;
  top: -60px;
  position: absolute;
  right: 80px;
}
.signup_button:hover {
  background-color: #45a049;
}
.signup_button:active {
  background-color: #3e8e41;
}
.error {
  font-size: 12px;
  position: absolute;
  top: 80px;
  color: red;
}
