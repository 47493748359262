.resizable-box {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1202px) {
  .resizable-box {
    width: 90vw;
    height: 90vh;
  }
}

body {
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

.check-card:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.checked-item {
  color: green;
  /*font-weight: bold;*/
}

.not-checked-item {
  /* color: #032c65; */
  color:grey
}

.card-zone-maps {
  flex: 0 0 calc(33.333% - 10px);
  margin: 10px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  
}
.card-zone-maps:hover{
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

}

.card-zone-maps.checked {
  background-color: yellowgreen;
}



.board-grid-maps {
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow-y: auto;
  height: 60%;
  overflow: auto;
}
.leaflet-container {
  width: 100%;
  height: 100vh;
}